import React, { Component } from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import NotFound from '../components/NotFound'
import LoginPanel from '../components/LoginPanel'
import RegisterPanel from '../components/RegisterPanel'
import ForgotPasswordPanel from '../components/ForgotPasswordPanel'

class AnonymousArea extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={LoginPanel} />
        <Route path="/register" component={RegisterPanel} />
        <Route path="/forgot-password" component={ForgotPasswordPanel} />
        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default withRouter(AnonymousArea)

import { hot } from 'react-hot-loader/root'
import React, { Component, Suspense } from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles'
import { orange, blue } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import ReactGA from 'react-ga'
import { setJWT } from './request'
import UserContext, { useUser } from './user.context'
import Frame from './components/Frame'
import AnonymousArea from './containers/AnonymousArea'
import Profile from './profile'
import Forms from './forms'
import Blogs from './blogs'
import Fragments from './fragments'
import request from './request'
import { notification } from 'antd'
notification.config({ placement: 'bottomRight' })

const theme = {
  primary: '#f79421',
  background: '#f0f2f5',
}

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      ...orange,
      500: theme.primary,
    },
    secondary: blue,
  },
})

// TODO: Replace with actual dashboard
const Dashboard = () => {
  const { user } = useUser()
  return <Redirect to={user.features.forms ? '/forms/' : '/blogs/'} />
}

class App extends Component {
  state = { user: null }
  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search)
    const token = sessionStorage.getItem('token')
    if (params.has('sst')) {
      const sst = params.get('sst')
      params.delete('sst')
      this.props.history.replace({
        ...this.props.location,
        search: params.toString(),
      })
      const response = await request('/users/login', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ sst }),
      })
      if (response.ok) {
        const { jwt } = await response.json()
        this.handleLogin(jwt)
        sessionStorage.setItem('token', jwt)
        ReactGA.event({
          category: 'User',
          action: 'Logged in via SST',
        })
        return
      } else if (token == null) {
        this.props.history.replace({
          pathname: '/',
          state: { goingTo: this.props.history.pathname },
        })
        this.setState({ user: false })
        ReactGA.event({
          category: 'User',
          action: 'Failed login via SST',
        })
        return
      }
    }
    if (token != null) {
      ReactGA.event({
        category: 'User',
        action: 'Logged in via session token',
      })
      return this.handleLogin(token)
    }
    this.setState({ user: false })
  }
  handleLogin = (jwt, redirect) => {
    const { exp, sub, email, firstName, lastName, features } = setJWT(jwt)
    if (exp * 1000 < Date.now()) {
      return this.setState({ user: false })
    }
    this.setState(
      { user: { _id: sub, email, firstName, lastName, features } },
      () => {
        if (redirect) {
          this.props.history.push(redirect)
        }
      },
    )
    ReactGA.set({ userId: sub })
    setTimeout(() => {
      // todo: handle refresh
    }, exp * 1000 - (Date.now() + 5000))
  }
  handleLogout = () => {
    ReactGA.event({
      category: 'User',
      action: 'Logged out',
    })
    ReactGA.set({ userId: null })
    sessionStorage.removeItem('token')
    this.props.history.replace('/')
    this.setState({ user: false })
  }
  getSidebar(path) {
    if (path.startsWith('/forms')) {
      return null
      // TODO: Add sidebar when interacting with forms
      // return (
      //   <Menu
      //     mode="inline"
      //     defaultSelectedKeys={['1']}
      //     defaultOpenKeys={['forms']}
      //     style={{ height: '100%', borderRight: 0 }}>
      //     <Menu.SubMenu
      //       key="forms"
      //       title={
      //         <span>
      //           <Icon type="form" />Forms
      //         </span>
      //       }>
      //       <FormsContext.Consumer>
      //         {({ forms }) => (
      //           <Fragment>
      //             {forms.map(({ _id }) => (
      //               <Menu.Item key={_id}>{_id}</Menu.Item>
      //             ))}
      //           </Fragment>
      //         )}
      //       </FormsContext.Consumer>
      //     </Menu.SubMenu>
      //   </Menu>
      // )
    }
    return null
  }
  render() {
    const { user } = this.state
    return (
      <MUIThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <UserContext.Provider
            value={{
              user,
              login: this.handleLogin,
              logout: this.handleLogout,
            }}>
            {user == null ? (
              <div>Loading...</div>
            ) : user === false ? (
              <AnonymousArea />
            ) : (
              <Frame sidebar={this.getSidebar(this.props.location.pathname)}>
                <Suspense fallback={<LinearProgress />}>
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/forms" component={Forms} />
                    <Route path="/blogs" component={Blogs} />
                    <Route path="/fragments" component={Fragments} />
                  </Switch>
                </Suspense>
              </Frame>
            )}
          </UserContext.Provider>
        </ThemeProvider>
      </MUIThemeProvider>
    )
  }
}

export default hot(withRouter(App))

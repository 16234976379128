import { useState, useEffect, useDebugValue } from 'react'

export const store = {
  state: {},
  add(name, val) {
    this.state = { ...this.state, [name]: val }
    this.setters.forEach(setter => setter(this.state))
  },
  setters: [],
}

export function useGlobalNamesStore() {
  const [state, set] = useState(store.state)
  useEffect(() => {
    store.setters.push(set)
    return () => store.setters.splice(store.setters.indexOf(set), 1)
  }, [set])
  useDebugValue(state)
  return [
    state,
    {
      add(name, val) {
        store.add(name, val)
      },
    },
  ]
}

import React, { useState, useLayoutEffect, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useUser } from '../user.context'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Lock from '@material-ui/icons/Lock'
import request from '../request'
import CenteredLogoPanel from './CenteredLogoPanel'

function usePersisted(defaultValue, key) {
  const [value, setValue] = useState(defaultValue)
  useLayoutEffect(() => {
    if (localStorage.getItem(key)) {
      setValue(JSON.parse(localStorage.getItem(key)).v)
    }
  }, [key])
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify({ v: value }))
  }, [value, key])
  return [value, setValue]
}

function LoginPanel({ history }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberUser, setRememberUser] = usePersisted(true, 'remember-me')
  const [error, setError] = useState(null)
  const [loggingIn, setLoggingIn] = useState(false)
  const { login } = useUser()
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoggingIn(true)
      await handleLogin({
        type: 'basic',
        email,
        password,
        rememberUser,
      })
    } catch (err) {
      const msg = err.message.includes('Incorrect')
        ? err.message
        : 'Failed to log in. Please try again later.'
      setError(msg)
      setLoggingIn(false)
    }
  }
  const handleLogin = async ({ rememberUser, type, ...body }) => {
    switch (type) {
      case 'basic': {
        const response = await request('/users/login', {
          method: 'POST',
          body,
        })
        if (response.ok) {
          const { jwt } = await response.json()
          login(jwt, history.location.state && history.location.state.goingTo)
          if (rememberUser) {
            sessionStorage.setItem('token', jwt)
          }
          ReactGA.event({
            category: 'User',
            action: 'Logged in via form',
          })
        } else {
          const { message } = await response.json()
          ReactGA.event({
            category: 'User',
            action: 'Failed login via form',
          })
          throw new Error(message)
        }
        break
      }
      default: {
        throw new Error(`Authentication type '${type}' not implemented.`)
      }
    }
  }

  return (
    <CenteredLogoPanel heading="Login">
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          type="email"
          label="Email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          required
        />
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
          type="password"
          label="Password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          required
        />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberUser}
              onChange={({ target }) => setRememberUser(target.checked)}
            />
          }
          label="Remember me"
        />
        <p>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loggingIn}>
            Log in
          </Button>
        </p>
        <p>
          Don't have an account? <Link to="/register">register now!</Link>
        </p>
        <p>
          <Link to="/forgot-password">Forgot password?</Link>
        </p>
      </form>
    </CenteredLogoPanel>
  )
}

export default LoginPanel

import { useState, useEffect } from 'react'
import fetch from 'unfetch'
import jsonwebtoken from 'jsonwebtoken'

export const BASE_URL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:3030'
    : window && window.location.hostname !== 'app.doomsdaytuna.com'
    ? 'https://api-staging.doomsdaytuna.io'
    : 'https://api.doomsdaytuna.io'

let bearer, decoded
function processOptions(opts) {
  opts.headers = opts.headers || {}
  if (bearer != null) {
    opts.headers.Authorization = bearer
  }
  if (opts.headers.Accept == null) {
    opts.headers.Accept = 'application/json'
  }
  if (typeof opts.body === 'object' && !(opts.body instanceof FormData)) {
    opts.headers['Content-Type'] = 'application/json'
    opts.body = JSON.stringify(opts.body)
  }
  return opts
}
export default function request(path, rawOpts = {}, { onProgress } = {}) {
  const opts = processOptions(rawOpts)
  if (onProgress) {
    return futch(`${BASE_URL}${path}`, opts, onProgress)
  }
  return fetch(`${BASE_URL}${path}`, opts)
}

export function setJWT(jwt) {
  bearer = `Bearer ${jwt}`
  decoded = jsonwebtoken.decode(jwt)
  return decoded
}

function futch(url, opts, onProgress) {
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest()
    xhr.open(opts.method || 'get', url)
    for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])
    xhr.onload = (e) =>
      res({
        ok: xhr.status < 400,
        json: async () => JSON.parse(e.target.responseText),
      })
    xhr.onerror = rej
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress
    xhr.send(opts.body)
  })
}

export function useRequest(url, opts) {
  const [res, setRes] = useState({ data: null, error: null, loading: true })
  useEffect(() => {
    ;(async () => {
      try {
        const res = await request(url, opts)
        setRes({ data: await res.json(), error: null, loading: false })
      } catch (error) {
        setRes({ data: null, error, loading: false })
      }
    })()
  }, [url, opts])
  return res
}

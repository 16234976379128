import React, { useState } from 'react'
import { withRouter, Switch, Route, NavLink, Link } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import MenuIcon from '@material-ui/icons/Menu'
import InboxIcon from '@material-ui/icons/Inbox'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb } from 'antd'
import { ucFirst } from 'change-case'
import ErrorBoundary from '../components/ErrorBoundary'
import NotFound from '../components/NotFound'
import { useGlobalNamesStore } from '../globalNames.store'
import { ReactComponent as DDTLogo } from '../assets/ddt-logo.svg'
import { useUser } from '../user.context'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      'appbar'
      'content'
      'menu'
    `,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '200px 1fr',
      gridTemplateAreas: `
        'menu appbar'
        'menu content'
      `,
    },
  },
  appbar: {
    gridArea: 'appbar',
  },
  menu: {
    gridArea: 'menu',
  },
  content: {
    gridArea: 'content',
  },
}))

function UserMenu() {
  const { logout } = useUser()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit">
        <AccountCircle />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <NavLink to="/profile">Profile</NavLink>
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

function Frame({ location, children }) {
  const classes = useStyles()
  const [globalNames] = useGlobalNamesStore()
  const { user } = useUser()
  const [drawOpen, setDrawOpen] = useState(false)

  const menu = (
    <List>
      <NavLink to="/">
        <ListItem style={{ justifyContent: 'center' }}>
          <ListItemIcon>
            <DDTLogo width="120" height="50" />
          </ListItemIcon>
        </ListItem>
      </NavLink>
      {user.features.forms && (
        <NavLink to="/forms/">
          <ListItem>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText>Forms</ListItemText>
          </ListItem>
        </NavLink>
      )}
      {user.features.blogs && (
        <NavLink to="/blogs/">
          <ListItem>
            <ListItemIcon>
              <VerticalSplitIcon />
            </ListItemIcon>
            <ListItemText>Blogs</ListItemText>
          </ListItem>
        </NavLink>
      )}
      {user.features.fragments && (
        <NavLink to="/fragments/">
          <ListItem>
            <ListItemIcon>
              <ArtTrackIcon />
            </ListItemIcon>
            <ListItemText>Fragments</ListItemText>
          </ListItem>
        </NavLink>
      )}
    </List>
  )
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={() => setDrawOpen((o) => !o)}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6">DDT App</Typography>
          <Breadcrumb style={{ flexGrow: 1, margin: '16px' }}>
            {
              ['Home', ...location.pathname.split('/')].filter(Boolean).reduce(
                ([crumbs, carryLoc], loc, i) => {
                  carryLoc += `${loc === 'Home' ? '' : loc}/`
                  const display = globalNames[loc] || ucFirst(loc)
                  return [
                    [
                      ...crumbs,
                      <Breadcrumb.Item key={i}>
                        <Link to={carryLoc}>{display}</Link>
                      </Breadcrumb.Item>,
                    ],
                    carryLoc,
                  ]
                },
                [[], ''],
              )[0]
            }
          </Breadcrumb>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <nav className={classes.menu}>
        <Hidden smUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawOpen}
            onClose={() => setDrawOpen((o) => !o)}
            ModalProps={{
              keepMounted: true,
            }}>
            {menu}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            variant="permanent"
            anchor="left"
            open
            PaperProps={{
              style: { width: 200 },
            }}>
            {menu}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div style={{ padding: '0 24px 24px' }}>
          <div
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}>
            <ErrorBoundary>
              <Switch>
                {children}
                <Route component={NotFound} />
              </Switch>
            </ErrorBoundary>
          </div>
        </div>
      </main>
    </div>
  )
}

export default withRouter(Frame)

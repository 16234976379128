import { createContext, useContext } from 'react'

const UserContext = createContext({
  get user() {
    throw new Error('No users in context.')
  },
  login() {
    throw new Error('Not implemented.')
  },
  logout() {
    throw new Error('Not implemented.')
  },
})

export const useUser = () => useContext(UserContext)

export default UserContext

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { LastLocationProvider } from 'react-router-last-location'
import './index.css'
import ErrorBoundary from './components/ErrorBoundary'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://fcc92cbe46ee4de297ee07074c316247@sentry.io/1287129',
    blacklistUrls: ['localhost', '127.0.0.1'],
  })
}

ReactGA.initialize('UA-71058389-3', {
  debug: process.env.NODE_ENV !== 'production',
})

const history = createBrowserHistory()
history.listen(location => {
  ReactGA.pageview(location.pathname)
})

ReactDOM.render(
  <ErrorBoundary>
    <Router history={history}>
      <LastLocationProvider>
        <App />
      </LastLocationProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
)
registerServiceWorker()

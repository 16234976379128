import React, { useState } from 'react'
import { Form, Icon, Input, Button } from 'antd'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'
import request from '../request'
import CenteredLogoPanel from './CenteredLogoPanel'

const FullWidthButton = styled(Button)`
  width: 100%;
`

function ForgotPasswordPanel({ form }) {
  const [complete, setComplete] = useState(null)
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setSubmitting(true)
        const response = await request('/users/reset-password-request', {
          method: 'POST',
          body: { email: values.email },
        })
        if (!response.ok) {
          const { message } = await response.json()
          ReactGA.event({
            category: 'User',
            action: 'Failed reset password',
          })
          throw new Error(message)
        }
        ReactGA.event({
          category: 'User',
          action: 'Reset password',
        })
        setComplete(true)
      } catch (err) {
        setError('Failed to reset password. Please try again later.')
        setSubmitting(false)
      }
    })
  }

  return (
    <CenteredLogoPanel heading="Forgot password">
      {complete ? (
        <p>Please check your email for further instructions.</p>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {form.getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email!' }],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="email"
                placeholder="Email"
              />
            )}
          </Form.Item>
          {error && <Form.Item style={{ color: 'red' }}>{error}</Form.Item>}
          <Form.Item>
            <FullWidthButton
              type="primary"
              htmlType="submit"
              loading={submitting}>
              Reset password
            </FullWidthButton>
          </Form.Item>
        </Form>
      )}
    </CenteredLogoPanel>
  )
}

export default Form.create()(ForgotPasswordPanel)

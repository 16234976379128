import React from 'react'
import styled from 'styled-components/macro'
import logo from '../assets/ddt-logo.svg'

const Base = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`
const Inner = styled.div`
  width: 300px;
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 2em;
  }
`

export default function CenteredLogoPanel({ children, heading }) {
  return (
    <Base>
      <Inner>
        <img src={logo} alt="Doomsday Tuna Logo" />
        <h1>{heading}</h1>
        {children}
      </Inner>
    </Base>
  )
}

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button } from 'antd'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'
import request from '../request'
import CenteredLogoPanel from './CenteredLogoPanel'
import { useUser } from '../user.context'

const FullWidthButton = styled(Button)`
  width: 100%;
`

function RegisterPanel({ history, form }) {
  const [error, setError] = useState(null)
  const [registering, setRegistering] = useState(false)
  const { login } = useUser()
  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setRegistering(true)
        await handleRegister({
          type: 'basic',
          rememberUser: true,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
        })
      } catch (err) {
        setRegistering(false)
        setError(err.message)
      }
    })
  }
  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback("Password confirmation doesn't match!")
    } else {
      callback()
    }
  }
  const handleRegister = async ({ rememberUser, ...body }) => {
    const response = await request('/users', {
      method: 'POST',
      body,
    })
    if (response.ok) {
      const { jwt } = await response.json()
      // TODO: send to onboarding
      login(jwt, '/forms')
      if (rememberUser) {
        sessionStorage.setItem('token', jwt)
      }
      ReactGA.event({
        category: 'User',
        action: 'Registered',
      })
    } else {
      const { message } = await response.json()
      ReactGA.event({
        category: 'User',
        action: 'Failed to register',
        label: message,
      })
      throw new Error(message)
    }
  }
  return (
    <CenteredLogoPanel heading="Register">
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          {form.getFieldDecorator('firstName', {
            rules: [
              { required: true, message: 'Please input your first name!' },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="First name"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('lastName', {
            rules: [
              { required: true, message: 'Please input your last name!' },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Last name"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={
                <Icon type="contacts" style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              type="email"
              placeholder="Email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('confirm-password', {
            rules: [
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Confirm password"
            />,
          )}
        </Form.Item>
        {error && <Form.Item style={{ color: 'red' }}>{error}</Form.Item>}
        <Form.Item>
          <FullWidthButton
            type="primary"
            htmlType="submit"
            loading={registering}>
            Register
          </FullWidthButton>
          Or <Link to="/">log in now!</Link>
        </Form.Item>
      </Form>
    </CenteredLogoPanel>
  )
}

export default Form.create()(RegisterPanel)

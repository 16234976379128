import React from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { withLastLocation } from 'react-router-last-location'
import { useUser } from '../user.context'

const Base = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Inner = styled.div`
  padding: 2em;
  text-align: center;
`

function NotFound({ lastLocation, location }) {
  ReactGA.event({
    category: 'Site',
    action: 'Hit 404',
    label: lastLocation
      ? `${location.pathname}, last: ${lastLocation.pathname}`
      : location.pathname,
  })
  const { user } = useUser()
  return (
    <Base>
      <Inner>
        <h1>Oh no! This page doesn't exist!</h1>
        {!user && (
          <p>
            You may need to{' '}
            <Link to={{ pathname: '/', state: { goingTo: location.pathname } }}>
              log in
            </Link>{' '}
            to access this area.
          </p>
        )}
        <p>
          {lastLocation && lastLocation.pathname !== '/' ? (
            <span>
              You can either <Link to={lastLocation.pathname}>go back</Link> or{' '}
              <Link to="/">head home</Link>.
            </span>
          ) : (
            <Link to="/">Head home.</Link>
          )}
        </p>
      </Inner>
    </Base>
  )
}

export default withLastLocation(NotFound)
